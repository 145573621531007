body {
  background-color: #111;
  font-size: 24px;
}
.App {
  text-align: center;
  color: #fff;
}

input {
  padding: 12px;
  font-size: 0.825em;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
li {
  margin-top: 6px;
}

p {
  color: #ddd;
  cursor: pointer;
  text-decoration: none;
  position: relative;
}

p:hover {
  text-decoration: line-through;
}